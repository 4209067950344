export const themeDimensions = { colorScheme: 'colorScheme', density: 'density', breakpoint: 'breakpoint', animation: 'animation' };
export const defaultThemeDimensions = { colorScheme: 'light', density: 'spacious', breakpoint: 'xs', animation: 'lively' };
export const colorSchemeModes = { light: 'Light', dark: 'Dark' };
export const densityModes = { spacious: 'Spacious', compact: 'Compact', dense: 'Dense' };
export const breakpointModes = { xs: 'XS', m: 'M', l: 'L' };
export const animationModes = { lively: 'Lively', minimal: 'Minimal' };
export const fileFormats = { json: 'JSON', css: 'CSS', cssVar: 'var(CSS)', scss: 'SCSS', js: 'Javascript', ios: 'iOS', android: 'Android' };
export const prodBaseUrl = 'https://design-tokens.cdn.allianz.com/';
export const versionsToExclude = ['0.0.7', '0.1.0', '0.2.0', '0.2.1', '0.3.0'];
export const noneBreakpointDimensionReleases = ['0.0.7', '0.1.0', '0.2.0', '0.2.1', '0.3.0', '0.4.0', '0.4.1', '0.5.0'];
export const firstReleaseWithBreakpointDimension = '0.6.0';
export const tokenTypes = {
	borderRadius: 'borderRadius',
	borderWidth: 'borderWidth',
	color: 'color',
	dimension: 'dimension',
	duration: 'duration',
	easing: 'easing',
	fontFamilie: 'fontFamilie',
	fontFamilies: 'fontFamilies',
	fontSize: 'fontSize',
	fontSizes: 'fontSizes',
	fontWeight: 'fontWeight',
	fontWeights: 'fontWeights',
	inset: 'inset',
	letterSpacing: 'letterSpacing',
	lineHeight: 'lineHeight',
	lineHeights: 'lineHeights',
	// eslint-disable-next-line id-blacklist
	number: 'number',
	other: 'other',
	scaling: 'scaling',
	shadow: 'shadow',
	sizing: 'sizing',
	spacing: 'spacing',
	textDecoration: 'textDecoration',
	time: 'time',
	typography: 'typography',
};
export const typoPreviewText = 'Lorem Ipsum';
